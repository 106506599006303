<template>
    <div class="order">
        <div class="header">
            <span :class="type===0 ? 'itemHover':'item'" @click="chooseType(0)">全部</span>
            <span :class="type===1 ? 'itemHover':'item'" @click="chooseType(1)">寄送类</span>
            <span :class="type===2 ? 'itemHover':'item'" @click="chooseType(2)">电子类</span>
            <span :class="type===3 ? 'itemHover':'item'" @click="chooseType(3)">预约类</span>
        </div>
        <div class="body" v-if="orderList.length > 0">
            <div class="item" v-for="(item,index) in orderList" :key="'order_'+index">
                <div class="title">
                    <img src="../assets/icon_left.png" alt=""/>
                    <span>{{item.name}}</span>
                    <img src="../assets/icon_right.png" alt=""/>
                </div>
                <div class="box">
                    <OrderProductItem v-for="data in item.data" :key="'order_pro_'+data.goods_id" :item="data" v-on:chooseAddress="chooseAddress"/>
                </div>
            </div>
        </div>
        <div class="empty" v-else>
            暂无数据
        </div>
        <div class="footer">
            <span @click="goHome">我的权益</span>
            <span @click="goOrder">兑换中心</span>
        </div>
    </div>
</template>

<script>
    import {Toast} from 'vant';
    import _ from 'lodash';
    import dayJs from 'dayjs';
    import config from "../config"
    import BottomCom from "../components/BottomCom";
    import {equityExchange, getConvertDetailList, getOrderList} from "../api/data";
    import OrderProductItem from "../components/OrderProductItem";

    export default {
        name: "Order",
        components: {OrderProductItem},
        data: function () {
            return {
                type: 0,
                orderList: []
            }
        },
        async beforeMount() {
            await this.queryOrderList();
        },
        methods: {
            // 查看详情
            chooseAddress(item) {
                //  1=实物,2=电子卡券,3=第三方票务,4=下午茶,5=酒店权益,6=景点门票,7=接送机
                console.log('查看详情=', item)
                this.$router.push({name: 'Orderdetail', query: {orderId: item.order_id}});

            },
            async queryOrderList() {
                let param = {
                    order_type: _.toInteger(this.type)
                };
                let res = await getOrderList(param);
                if (_.toInteger(res.code) === 1) {
                    this.orderList = res.data.data;
                    // this.orderList = [];
                } else {
                    Toast.fail(res.msg);
                }
            },
            async chooseType(type) {
                this.type = type;
                await this.queryOrderList();
            },
            async onSubmit() {
                if (this.convertCode == null || this.convertCode == '') {
                    Toast.fail("请先输入权益码");
                    return false;
                }
                if (this.verify == null || this.verify == '') {
                    Toast.fail("请输入图形验证码");
                    return false;
                }
                let params = {
                    yzm: this.verify,
                    barcode: this.convertCode,
                };
                let res = await equityExchange(params);
                if (_.toInteger(res.code) === 1) {
                   await this.goHome();
                } else {
                    this.changeVerify();
                    Toast.fail(res.msg);
                }
            },
            async goHome() {
                await this.$router.push('/')
            },
            async goOrder() {
                await this.queryOrderList();
            }
        }
    }
</script>

<style scoped>
    .order {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 124px;
        box-sizing: border-box;
    }

    .order .header {
        width: 690px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .order .header span.item {
        flex: 1;
        margin: 0 10px;
        display: flex;
        height: 60px;
        border: 2px solid #E6C7A1;
        border-radius: 8px;
        font-size: 28px;
        font-weight: 400;
        color: #F8EBDC;
        justify-content: center;
        align-items: center;
    }

    .order .header span.itemHover {
        flex: 1;
        margin: 0 10px;
        display: flex;
        height: 62px;
        background: linear-gradient(135deg, #FCF4EA 0%, #E3C197 100%);
        border-radius: 8px;
        font-size: 28px;
        font-weight: 500;
        color: #24272B;
        justify-content: center;
        align-items: center;
    }

    .order .header span:last-child {
        margin-right: 0px;
    }

    .order .header span:first-child {
        margin-left: 0px;
    }

    .order .empty {
        width: 690px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-weight: bold;
        color: #F8EBDC;
        margin: 0 25px;
    }

    .order .body {
        width: 690px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
    }

    .order .body .item {
        margin-top: 30px;
        width: 690px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(135deg, #FFFAF3 0%, #E2BE93 100%);
        border-radius: 16px;
        padding: 30px 24px;
    }

    .order .body .item .title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .order .body .item .title span {
        font-size: 32px;
        font-weight: bold;
        color: #A05923;
        margin: 0 25px;
    }

    .order .body .item .title img {
        width: 59px;
        height: 28px;
    }

    .order .body .item .box {
        width: 100%;
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .order .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .order .footer span {
        flex: 1;
        margin: 0 20px;
        height: 80px;
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .order .footer span:first-child {
        border: 2px solid #222529;
        color: #212429;
    }

    .order .footer span:last-child {
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        color: #E2BE93;
    }
</style>
