<template>
    <div class="orderProductItem" @click="changeAddress(item)">
        <div class="image">
            <img :src="item.image"/>
        </div>
        <div class="body">
            <div class="top van-ellipsis">{{item.goods_name}}</div>
            <div class="desc van-multi-ellipsis--l2">
                {{item.goods_Introduction}}
            </div>
            <div class="group">
                <div class="price">市场价:￥{{item.price}}</div>
                <div class="button">查看详情</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderProductItem",
        props: ['item'],
        methods: {
            changeAddress(item) {
                this.$emit("chooseAddress",item)
            }
        }
    }
</script>

<style scoped>
    .orderProductItem {
        width: 642px;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #222529 100%);
        border-radius: 12px;
        margin-bottom: 25px;
        position: relative;
        box-sizing: border-box;
        padding: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .orderProductItem .image {
        margin-right: 20px;
        width: 158px;
        height: 158px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .orderProductItem .image img {
        max-width: 158px;
        width: 100%;
        max-height: 158px;
    }

    .orderProductItem .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .orderProductItem .body .top {
        width: 380px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 27px;
        color: #E8C8AF;
    }

    .orderProductItem .body .desc {
        width: 100%;
        margin-top: 15px;
        font-size: 22px;
        line-height: 30px;
        color: #B19485;
    }


    .orderProductItem .body .group {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .orderProductItem .body .group .price {
        font-size: 20px;
        color: #E8C8AF;
    }

    .orderProductItem .body .group .price span {
        font-size: 29px;
        font-weight: bold;
        color: #E8C8AF;
    }

    .orderProductItem .body .group .button {
        width: 142px;
        height: 54px;
        background: linear-gradient(125deg, #FEF7EE 0%, #E3C198 100%);
        border-radius: 27px;
        font-size: 24px;
        font-weight: 400;
        color: #1F1F2B;
        display: flex;
        justify-content: center;
        align-items: center;
    }


</style>
